import api from '@/services/api/api';

export const usePopularArticles = ({ per_page = 30 }) => {
  const popularArticles = useQuery(
    ['popularArticles'],
    async () => {
      const { data } = await api.articles.getPopularArticles({ per_page });
      return data;
    },
    { staleTime: Infinity, select: ({ data }) => data },
  );

  onServerPrefetch(async () => {
    await popularArticles.suspense();
  });

  return {
    popularArticles,
  };
};
